var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center align-items-md-center"},[_c('div',{staticClass:"col-md-11"},[_c('div',{staticClass:"card full-height bg-ligth"},[_c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Validacao")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9 mt-4"},[_c('treeselect',{attrs:{"multiple":false,"options":_vm.lista_eventos,"placeholder":"Selecione o evento..."},on:{"input":function($event){return _vm.preenxerCampos()}},model:{value:(_vm.eventos_id),callback:function ($$v) {_vm.eventos_id=$$v},expression:"eventos_id"}})],1)])]),_c('div',{staticClass:"card-body table-responsive"},[_c('div',{staticClass:"row"},[_c('b-table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless text-uppercase mt-3",attrs:{"fields":[
              'ingresso',
              'lote',
              'quantidade',
              'sim',
            

          
            ],"items":_vm.totais,"per-page":_vm.perPage,"current-page":_vm.currentPage,"id":"perfil-table","show-empty":"","empty-text":"Nenhum registro encontrado!"},scopedSlots:_vm._u([{key:"head(sim)",fn:function(){return [_c('span',[_vm._v("validados")])]},proxy:true}])}),_c('div',{staticClass:"col-md-9 pt-3"},[_c('div',{staticClass:"card text-center"},[_c('p',{staticStyle:{"font-size":"20px"}},[_vm._v(" quantidade: "+_vm._s(_vm.total['quant'])+" validado: "+_vm._s(_vm.total['sim'])+" faltam: "+_vm._s(_vm.total['quant'] -_vm.total['sim'])+" ")])])])],1),_c('b-table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless text-uppercase mt-3",attrs:{"fields":[
              'cliente',

              'ingresso',
              'status',
              'validado',

          
            ],"items":_vm.lista_validacao,"per-page":_vm.perPage,"current-page":_vm.currentPage,"id":"perfil-table","show-empty":"","empty-text":"Nenhum registro encontrado!"},scopedSlots:_vm._u([{key:"head(acoes)",fn:function(){return [_c('span')]},proxy:true},{key:"head(validado)",fn:function(){return [_c('span',[_vm._v("data validação")])]},proxy:true},{key:"cell(status)",fn:function({ item }){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2 pt-3",class:{
                  'label-light-success': item.status === 1,
                  'label-light-danger': item.status === null,
                }},[_vm._v(" "+_vm._s(item.status === 1 ? "Validado" : "Não validado")+" ")])]}},{key:"cell(validado)",fn:function({ item }){return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.status === 1),expression:"item.status === 1"}]},[_vm._v(_vm._s(item.validado))])]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.lista_validacao.length,"per-page":_vm.perPage,"aria-controls":"perfil-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }