<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Validacao</h3>
          <div class="row">
            <div class="col-md-9 mt-4">
              <!-- <label class="col-md-12">Eventos:*</label> -->
              <treeselect
                :multiple="false"
                :options="lista_eventos"
                placeholder="Selecione o evento..."
                v-model="eventos_id"
                @input="preenxerCampos()"
              />
            </div>
          </div>
        </div>
      



        <div class="card-body table-responsive">
          <div class="row">
<!--           
            <div class="col-md-3 " v-for="(item, index) in totais" :key="index">
                  <v-card elevation="10" class=" text-center">
                    <p style="font-size: 15px">
                      {{ item.ingresso }}:
                      <br>
                    validado:  {{ item.sim }}
                    <br>
                     nao validado: {{ item.nao }}
            



                    </p>
                  </v-card>
            </div>
        -->
            <b-table
            
            :fields="[
              'ingresso',
              'lote',
              'quantidade',
              'sim',
            

          
            ]"
            :items="totais"
            :per-page="perPage"
            :current-page="currentPage"
            id="perfil-table"
            class="table table-head-custom  table-vertical-center table-head-bg table-borderless text-uppercase mt-3"
            show-empty
            empty-text="Nenhum registro encontrado!"
            >
            <template #head(sim)><span>validados</span></template>
          
          </b-table>

          
              <div class="col-md-9 pt-3">
              <div class="card text-center">
                <p style="font-size: 20px">
                  quantidade: {{ total['quant'] }}
                  validado: {{ total['sim']  }}
                  faltam: {{   total['quant'] -total['sim'] }}


                </p>
              </div>
          
            </div>
            </div>
      

       
          <b-table
            :fields="[
              'cliente',

              'ingresso',
              'status',
              'validado',

          
            ]"
            :items="lista_validacao"
            :per-page="perPage"
            :current-page="currentPage"
            id="perfil-table"
            class="table table-head-custom  table-vertical-center table-head-bg table-borderless text-uppercase mt-3"
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #head(validado)><span>data validação</span></template>
            <!-- <template #cell(created_at)="{ item }"
              ><span>{{ item.created_at | formatEnToBr }}</span></template
            > -->
            <template #cell(status)="{ item }">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': item.status === 1,
                  'label-light-danger': item.status === null,
                }"
              >
                {{ item.status === 1 ? "Validado" : "Não validado" }}
              </span></template
            >

            <template #cell(validado)="{ item }"> <span v-show="item.status === 1">{{ item.validado }}</span> </template>

            <!-- <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button
                  v-show="lista_permissoes_filial.u_Fatura"
                  @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button
                  v-show="lista_permissoes_filial.lock_Fatura"
                  @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-trash text-danger"></i>
                </button>

                <button
                  v-show="lista_permissoes_filial.u_Fatura"
                  @click="showModal(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-plus text-success"></i>
                </button>
              </div>
            </template> -->
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_validacao.length"
            :per-page="perPage"
            aria-controls="perfil-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
    <!-- <v-dialog v-model="modal_fatura" max-width="900" persistent>
      <template>
        <v-card>
          <v-card-title>Novo Lote</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="form-group row">
              <div class="col-md-3">
                <label class="col-md-12">Titulo:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.tipo"
                  placeholder="Digite o tipo do Fatura"
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Tipo:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.tipo2"
                  placeholder="Digite o tipo do Fatura"
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Lote:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.lote"
                  placeholder="Digite o lote do Fatura"
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Obs:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.obs"
                  placeholder="Digite o lote do Fatura"
                />
              </div>

              <div class="col-md-3">
                <label class="col-md-12">Valor:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.valor"
                  placeholder="Digite o Valor do Fatura"
                  @input="valorFormat"
                  v-mask="variableWithMask"
                />
              </div>
              <div class="col-md-6 pt-1">
                <label class="col-md-12">Quantidade lote:</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.quantidade_lote"
                  placeholder="Quantidade de Fatura do lote"
                />
              </div>
              <div class="row form-group col-md-12 pt-2">
              <div class="col-md-10 pull-left">
                <div class="md-radio-inline text-uppercase">
                  <label>Status:*</label>
                  <div class="space d-flex">
                    <b-form-radio
                      :inline="true"
                      value="2"
                      v-model="form.status"
                    >
                      inativo
                    </b-form-radio>
                    <b-form-radio
                      :inline="true"
                      value="1"
                      v-model="form.status"
                    >
                      ativo
                    </b-form-radio>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="modal_fatura = false">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitNovoLote">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog> -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import dateFormat from "@/core/mixins/dateFormat";
import EventoService from "@/core/services/evento.service";
import moneyFormat from "@/core/mixins/moneyFormat";

export default {
  mixins: [fireAlert, dateFormat, moneyFormat],

  data() {
    return {
      currentPage: 1,
      perPage: 10,
      eventos_id: EventoService.getEventoId(),
      modal_fatura: false,
      fatura: null,

      variableWithMask: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Faturas" }]);
  },

  created() {
    this.listar_validacao();
  },
  computed: {
    lista_eventos() {
      return EventoService.getEventos().map((cat) => ({
        id: cat.id,
        label: cat.id + " - " + cat.nome_evento,
      }));
    },
    lista_validacao() {
      return this.$store.state.validacao.lista_validacao['validacoes'].filter((item) => {
        if (!this.eventos_id) return true;
        return (
          String(item["eventos_id"])
            .toLowerCase()
            .indexOf(this.eventos_id + "".toLowerCase()) !== -1
        );
      });
    },
    totais() {
      return this.$store.state.validacao.lista_validacao['totais']
    },
    total() {
      return this.$store.state.validacao.lista_validacao['total']
    },
    
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.validacao.mensagem_alert;
    },

  
  },
  methods: {
    async listar_validacao() {
      await this.$store.dispatch("validacao/listar_validacao",EventoService.getEventoId());
      this.$store.dispatch("configEmpresa/atualizar", "");
      console.log(this.pix);

      // this.eventos_id =  this.$route.params.eventos_id
    },
    atualizar(value) {
      this.$router.push({ name: "createFaturas" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },

   
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara este PDV no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("validacao/bloquear_fatura", value);
      this.fireAlert({
        ...this.mensagem_alert,
      });
    },
    showModal(item) {
      this.form = {
        obs2: item.obs2,

        desc1: item.desc1,
        desc2: item.desc2,
        desc3: item.desc3,
        cor: item.cor,
        status: item.status,

        eventos_id: item.eventos_id,
        setor_id: item.setor_id,

        tipo: item.tipo,
        tipo2: item.tipo2,

        obs: 1 + parseInt(item.obs.replace(/[^0-9]/g, "")) + "° LOTE",
        quantidade_lote: item.quantidade_lote,
        lote: parseInt(item.lote) + 1,
        valor: null,
      };
      this.fatura = item;
      this.modal_fatura = true;
    },

  async  preenxerCampos() {
      EventoService.setEventoId(this.eventos_id);
      await this.$store.dispatch("validacao/listar_validacao",EventoService.getEventoId());

      // this.fireAlert({
      //   tipo: "success",
      //   msg: "Selecionado Evento Global",
      // });
    },
    valorFormat() {
      let pa = "";

      for (let i = 0; i < this.form.valor.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }

      this.variableWithMask = "R$" + pa + "#,##";
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>